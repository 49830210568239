  export const navigationData = [
    {
        id:1,
        title:'Home',
        path:'/'
    },{
        id:3,
        title:'Projects',
        path: '/projects',
    },{
        id:4,
        title:'About us',
        path: '/about/',
    },{
        id:5,
        title:'Work With Us',
        path: '/contactUs/',
    }]